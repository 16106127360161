<template>
  <div>
    <b-modal size="xl" hide-footer v-model="modal_box_summary" class="w-100" title="Resumen de Caja">
        <b-tabs align="center" content-class="mt-3">
          <b-tab title="Pagos" active>
            <div class="table-responsive mt-3 height-table">
              <table class="table table-hover table-bordered">
                <thead>
                  <tr>
                    <th width="3%" class="text-center">#</th>
                    <th width="10%" class="text-center">Fecha</th>
                    <th width="10%" class="text-center">N° Pago</th>
                    <th width="45%" class="text-center">Socio</th>
                    <th width="15%" class="text-center">M. Pago</th>
                    <th width="10%" class="text-center">Total</th>
              
                  </tr>
                </thead>
                <tbody v-for="(item, it) in loan_payments" :key="it">
                  <tr>
                    <td class="text-center">{{ it + 1 }}</td>
                    <td class="text-center"> {{ item.payment_date  }}</td>
                    <td class="text-center"> {{ item.code  }}</td>
                    <td class="text-left"> {{ item.name + " - "+ item.document_number  }}</td>
                    <td class="text-left"> {{ NameMethodPayment(item.payment_method)  }}</td>
                    <td class="text-right"> {{ item.total  }}</td>
               
                  </tr>
                </tbody>
              </table>
            </div>
            <b-row class="mt-4">
              <b-col md="8">
                <b-pagination v-model="loan_payment_currentPage"  v-on:input="ListLoanPayment"  :total-rows="loan_payment_rows"  :per-page="loan_payment_perPage" align="center" ></b-pagination>
              </b-col>
              <b-col md="4 text-center">
                <p>Pagina Actual: {{ loan_payment_currentPage }}</p>
              </b-col>
            </b-row>
          </b-tab>


          <b-tab title="Aportes">
            <div class="table-responsive mt-3 height-table">
              <table class="table table-hover table-bordered">
                <thead>
                  <tr>
                    <th width="3%" class="text-center">#</th>
                    <th width="10%" class="text-center">Fecha</th>
                    <th width="10%" class="text-center">N° Pago</th>
                    <th width="45%" class="text-center">Socio</th>
                    <th width="15%" class="text-center">M. Pago</th>
                    <th width="10%" class="text-center">Total</th>
                  </tr>
                </thead>
                <tbody v-for="(item, it) in contributions" :key="it">
                  <tr>
                    <td class="text-center">{{ it + 1 }}</td>
                    <td class="text-center"> {{ item.broadcast_date  }}</td>
                    <td class="text-center"> {{ item.number  }}</td>
                    <td class="text-left"> {{ item.partner_name + " - "+ item.partner_document_number  }}</td>
                    <td class="text-left"> {{ NameMethodPayment(item.payment_method)  }}</td>
                    
                    <td class="text-right"> {{ item.total  }}</td>
                
                  </tr>
                </tbody>
              </table>
            </div>
            <b-row class="mt-4">
              <b-col md="8">
                <b-pagination v-model="contribution_currentPage"  v-on:input="ListContributionSummary"  :total-rows="contribution_rows"  :per-page="contribution_perPage" align="center" ></b-pagination>
              </b-col>
              <b-col md="4 text-center">
                <p>Pagina Actual: {{ contribution_currentPage }}</p>
              </b-col>
            </b-row>
          </b-tab>


          <b-tab title="Pagos Anticipados">
            <div class="table-responsive mt-3 height-table">
              <table class="table table-hover table-bordered">
                <thead>
                  <tr>
                    <th width="3%" class="text-center">#</th>
                    <th width="10%" class="text-center">Fecha</th>
                    <th width="10%" class="text-center">N° Pago</th>
                    <th width="45%" class="text-center">Socio</th>
                    <th width="15%" class="text-center">M. Pago</th>
                    <th width="10%" class="text-center">Total</th>
              
                  </tr>
                </thead>
                <tbody v-for="(item, it) in prepaids" :key="it">
                  <tr>
                    <td class="text-center">{{ it + 1 }}</td>
                    <td class="text-center"> {{ item.payment_date  }}</td>
                    <td class="text-center"> {{ item.code  }}</td>
                    <td class="text-left"> {{ item.name + " - "+ item.document_number  }}</td>
                    <td class="text-left"> {{ NameMethodPayment(item.payment_method)  }}</td>
                    <td class="text-right"> {{ item.total  }}</td>
               
                  </tr>
                </tbody>
              </table>
            </div>
            <b-row class="mt-4">
              <b-col md="8">
                <b-pagination v-model="prepaid_currentPage"  v-on:input="ListPrepaidSummary"  :total-rows="prepaid_rows"  :per-page="prepaid_perPage" align="center" ></b-pagination>
              </b-col>
              <b-col md="4 text-center">
                <p>Pagina Actual: {{ prepaid_currentPage }}</p>
              </b-col>
            </b-row>
          </b-tab>



          <b-tab title="Egresos">
            <div class="table-responsive mt-3 height-table">
              <table class="table table-hover table-bordered">
                <thead>
                  <tr>
                    <th width="3%" class="text-center">#</th>
                    <th width="10%" class="text-center">Fecha</th>
                    <th width="10%" class="text-center">N° Pago</th>
                    <th width="45%" class="text-center">Descripción</th>
                    <th width="15%" class="text-center">M. Pago</th>
                    <th width="10%" class="text-center">Total</th>
                  </tr>
                </thead>
                <tbody v-for="(item, it) in extraordinary_expenses" :key="it">
                  <tr>
                    <td class="text-center">{{ it + 1 }}</td>
                    <td class="text-center"> {{ item.broadcast_date }}</td>
                    <td class="text-center"> {{ item.number }}</td>
                    <td class="text-left"> {{ item.description }}</td>
                    <td class="text-left"> {{ NameMethodPayment(item.payment_method) }}</td>
                    <td class="text-right"> {{ item.total}} </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <b-row class="mt-4">
              <b-col md="8">
                <b-pagination v-model="extraordinary_expense_currentPage"  v-on:input="ListExtraordinaryExpenseSummary"  :total-rows="extraordinary_expense_rows"  :per-page="extraordinary_expense_perPage" align="center" ></b-pagination>
              </b-col>
              <b-col md="4 text-center">
                <p>Pagina Actual: {{ extraordinary_expense_currentPage }}</p>
              </b-col>
            </b-row>
          </b-tab>


          <b-tab title="Otros">
            <div class="table-responsive mt-3 height-table">
              <table class="table table-hover table-bordered">
                <thead>
                  <tr>
                     <th width="3%" class="text-center">#</th>
                    <th width="10%" class="text-center">Fecha</th>
                    <th width="10%" class="text-center">N° Pago</th>
                    <th width="37%" class="text-center">Socio</th>
                    <th width="8%" class="text-center">Tipo</th>
                    <th width="15%" class="text-center">M. Pago</th>
                    <th width="10%" class="text-center">Total</th>
                  </tr>
                </thead>
                <tbody v-for="(item, it) in others" :key="it">
                  <tr>
                    <td class="text-center">{{ it + 1 }}</td>
                    <td class="text-center"> {{ item.broadcast_date }}</td>
                    <td class="text-center"> {{ item.number }}</td>
                    <td class="text-left"> {{ item.partner_name + " - "+ item.partner_document_number  }}</td>
                    <td class="text-center"> 
                      <span v-if="item.movement_type == 1">ING</span>
                      <span v-if="item.movement_type == 2">SAL</span>
                    </td>
                    <td class="text-left"> {{ NameMethodPayment(item.payment_method) }}</td>
                    <td class="text-right"> {{ item.total}} </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <b-row class="mt-4">
              <b-col md="8">
                <b-pagination v-model="other_currentPage"  v-on:input="ListOtherSummary"  :total-rows="other_rows"  :per-page="other_perPage" align="center" ></b-pagination>
              </b-col>
              <b-col md="4 text-center">
                <p>Pagina Actual: {{ other_currentPage }}</p>
              </b-col>
            </b-row>
          </b-tab>



        </b-tabs>
    </b-modal>

    <LoadingComponent :is-visible="isLoading"/>
 
  </div>
</template>
<style>



</style>
<script>
const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
import { mapState,mapActions } from "vuex";
import EventBus from "@/assets/js/EventBus";
import CodeToName from "@/assets/js/CodeToName";
import LoadingComponent from './../../pages/Loading'
var moment = require("moment");



export default {
  name: "ModalsProduct",
  components:{
    LoadingComponent,

  },
  data() {
    return {
        isLoading: false,
        modal_box_summary:false,
        module:'Box',
        role:'',
        id_box:'',
        
        loan_payment_perPage: 15,
        loan_payment_currentPage: 1,
        loan_payment_rows: 0,
        loan_payments: [],

        contribution_perPage: 15,
        contribution_currentPage: 1,
        contribution_rows: 0,
        contributions: [],

        prepaid_perPage: 15,
        prepaid_currentPage: 1,
        prepaid_rows: 0,
        prepaids: [],


        extraordinary_expense_perPage: 15,
        extraordinary_expense_currentPage: 1,
        extraordinary_expense_rows: 0,
        extraordinary_expenses: [],

        other_perPage: 15,
        other_currentPage: 1,
        other_rows: 0,
        others: [],



    
    };
  },
  created (){
    
  },
  mounted () {
    EventBus.$on('ModalBoxSummaryShow', (data) => {
      this.role = data.role;
      this.id_box = data.id_box;
      this.modal_box_summary = true;
      this.ListLoanPayment();
      this.ListContributionSummary();
      this.ListPrepaidSummary();
      this.ListOtherSummary();
      this.ListExtraordinaryExpenseSummary();
    });
    
  },
  methods: {
      NameMethodPayment,

      ListLoanPayment,
      ListContributionSummary,
      ListPrepaidSummary,
      ListOtherSummary,
      ListExtraordinaryExpenseSummary,
  },
  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
    user: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user;
    },
  },
};

function NameMethodPayment(code) {
  return CodeToName.NameMethodPayment(code);
}

function ListLoanPayment() {
  let me = this;
  let url = me.url_base + "box/loan-payment-summary/"+me.id_box+ "?page=" + this.loan_payment_currentPage;
  axios({
    method: "GET",
    url:url,
    headers: { token: me.token, module: me.module, role: me.role},
  })
  .then(function (response) {
    if (response.data.status == 200) {
        me.loan_payment_rows = response.data.result.total;
        me.loan_payments = response.data.result.data;
    }
  })
  .catch((error) => {
  });
}


function ListContributionSummary() {
  let me = this;
  let url = me.url_base + "box/contribution-summary/"+me.id_box+ "?page=" + this.contribution_currentPage;
  axios({
    method: "GET",
    url:url,
    headers: { token: me.token, module: me.module, role: me.role},
  })
  .then(function (response) {
    if (response.data.status == 200) {
        me.contribution_rows = response.data.result.total;
        me.contributions = response.data.result.data;
    }
  })
  .catch((error) => {
  });
}


function ListPrepaidSummary() {
  let me = this;
  let url = me.url_base + "box/prepaid-summary/"+me.id_box+ "?page=" + this.prepaid_currentPage;
  axios({
    method: "GET",
    url:url,
    headers: { token: me.token, module: me.module, role: me.role},
  })
  .then(function (response) {
    if (response.data.status == 200) {
        me.prepaid_rows = response.data.result.total;
        me.prepaids = response.data.result.data;
    }
  })
  .catch((error) => {
  });
}



function ListOtherSummary() {
  let me = this;
  let url = me.url_base + "box/other-summary/"+me.id_box+ "?page=" + this.other_currentPage;
  axios({
    method: "GET",
    url:url,
    headers: { token: me.token, module: me.module, role: me.role},
  })
  .then(function (response) {
    if (response.data.status == 200) {
        me.other_rows = response.data.result.total;
        me.others = response.data.result.data;
    }
  })
  .catch((error) => {
  });
}


function ListExtraordinaryExpenseSummary() {
  let me = this;
  let url = me.url_base + "box/extraordinary-expense-summary/"+me.id_box+ "?page=" + this.extraordinary_expense_currentPage;
  axios({
    method: "GET",
    url:url,
    headers: { token: me.token, module: me.module, role: me.role},
  })
  .then(function (response) {
    if (response.data.status == 200) {
        me.extraordinary_expense_rows = response.data.result.total;
        me.extraordinary_expenses = response.data.result.data;
    }
  })
  .catch((error) => {
  });
}




</script>
